@w: 75rem;
.user-select-none{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.workbench{
  background-color: #f5f5f5;
  min-height: 100vh;
  .user-select-none();
  .wing-blank{
    padding: 0 18px;
    background-color: #f5f5f5;
  }
  .am-carousel{
    .carousel-img-box{
      height: 800/@w;
      width: 100%;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20/@w;
      }
    }
  }
  .cate-box{
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 80/@w 0;
    .cate-name{
      font-size: 100/@w;
      line-height: 160/@w;
      text-indent: 100/@w;
      font-weight: 600;
    }
    .app-box{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .app-item{
        position: relative;
        width: 20%;
        margin: 0 2.5%;
        font-size: 64/@w;
        line-height: 106/@w;
        margin-top: 80/@w;
        text-align: center;
        img{
          width: 260/@w;
          height: 260/@w;
          border-radius: 60/@w;
        }
        .del-icon{
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: red;
          position: absolute;
          top: -10px;
          right: 0;
          color: #fff;
          .del{
            display: block;
            width: 8px;
            height: 1px;
            background-color: #fff;
          }
        }
      }
    }
  }
  .no-data{
    padding-top: 600/@w;
    margin: 0 auto;
    text-align: center;
    .tips{
      padding-top: 10px;
      color: #888;
    }
  }
}

.building-box{
  position: fixed;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .tips{
    padding-top: 16px;
    color: #888;
  }
}
@w: 75rem;

.announce {
  height: 100% !important;
  .releases{
    text-align: center;
    padding: 10px 0;
    height: 48px;
  }
  .release{
    width: 60px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border: 1px solid #D3D3D3;
    border-radius: 16px;
    z-index: 20;
    background: #fff;
    // position: fixed;
    // right: 15px;
    // bottom: 58px;
  }
  .am-navbar {
    background-color: #0ABEF5;
  }

  .content {
    height: 100% !important;

    .am-list-item {
        .am-textarea-control {
          height       : 100% !important;
          padding-left : 12px;
          padding-right: 15px;
          padding-top  : 10px;
          padding-bottom: 10px;
          textarea{
            padding-right: 17px;
          }
        }
   

    }
  }

  .foot {
    position       : fixed;
    left           : 0;
    bottom         : 0;
    width          : 100%;
    height         : 31px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 12px 0 22px 0;
    background     : #ffffff;

    .lineLeft,
    .lineRight {
      width        : 10.6%;
      // border    : 1px solid transparent;
      border-bottom: 0.5px solid #D7D7D7;
      height       : 1px;
      padding      : 0;
      margin       : 0 0 0 0;
    }

    .lineLeft {
      margin-right: 2%;
    }

    .lineRight {
      margin-left: 2%;
    }

    .text {
      padding: 0;
      margin : 0 0 0 0;
      float  : left;
      color  : #888888;
    }
  }

  .am-textarea-control {
    textarea:disabled {
      color  : unset;
      opacity: 1;
    }
  }
}